import React from "react";
import PropTypes from "prop-types";

import ArticleListItem from "./ArticleListItem";

const ArticleList = ({ articles, containerClass, itemClass }) => {

  return (
		<ul id="articles" className={`wrapper articles ${containerClass}`}>
      {articles.map(article => (
				<ArticleListItem key={article.title} itemClass={itemClass} {...article} />
      ))}
    </ul>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ArticleList;