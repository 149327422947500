import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/common/layout";
import Articles from "../components/articles/Articles";
import PageHeader from "../components/pageheader";

const PostPage = ({ data, pageContext, location, lang }) => {
	const { post } = pageContext

	const language = lang;
	let isEn = false;
	if (language === "en") {
		isEn = true;
	}
	const content = {
		articlePanel: { en: "Articles", is: "Greinar" },
	};

	return (
  <Layout location={location} lang={lang}>

			<section id="main" className="wrapper">
					<PageHeader
						lang={lang}
						location={location}
						title={post.frontmatter.title}
						author={post.frontmatter.author}
						featuredImage={post.frontmatter?.featuredImage}
					/>
    <div id="wrapper">
				<div className="page-content">
						<div>
					<div className="aside">
							<p><strong>{post.frontmatter.author}</strong><br /><br />
								{post.frontmatter.authorTitle}<br /><br />
								{post.frontmatter.authorOrg}</p>
					</div>
					<div className="main">
          <div
            dangerouslySetInnerHTML={{
							__html: post.html
						}}
						></div>
						</div>
					</div>
        </div>
				</div>
      </section>
		<div className="more-items-panel">
			<h2>{isEn ? content.articlePanel.en : content.articlePanel.en}</h2>
		<Articles articles={data.natureintransitionJson.articles} />
		</div>
  </Layout>
);};

export default PostPage;


export const postQuery = graphql`
         query($slug: String!) {
           site {
             siteMetadata {
               title
             }
           }
           markdownRemark(frontmatter: { slug: { eq: $slug } }) {
             id
             html
             frontmatter {
               slug
               title
               path
               name
               country
							 featuredImage {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							 }
             }
           }
           natureintransitionJson {
             title
             articles {
               title
               to {
                 title
                 href
               }
             }
           }
         }
       `;